import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query ZakatFR {
    heroImage: file(relativePath: {eq: "project-zakat-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Give your Zakat: Today, human beings are still dying because of the cold and isolation." description="Through its ZAKAT program, UMMATY offers you the possibility to fulfill this obligation in a single click. We ensure that 100% of your Zakat donation amounts (no administration fees applied) are spent on the beneficiaries categories mentioned in the Holy Qur'an." mdxType="SEO" />
    <CauseHero purpose="zakat" title="Donnez votre zakat" text="« Prélève de leurs biens une aumône par laquelle tu les purifies et les bénis, et prie pour eux »" footer="[9:103, Qur'an]." data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <div className="p-wrapper">
      <p>{`Le Saint Coran mentionne huit causes spécifiques ou huit catégories de gens pour lesquelles la Zakat peut être déboursée: “ Les Ṣadaqāts (Zakat) ne sont destinés qu'aux pauvres, aux indigents, à ceux qui y travaillent, à ceux dont les cœurs sont à gagner (à l'Islam), à l'affranchissement des jougs, à ceux qui sont lourdement endettés, dans le sentier d'Allah, et au voyageur (en détresse). C'est un décret d'Allah! Et Allah est Omniscient et Sage“ `}{`[9:60, Coran]`}{`.`}</p>
      <p>{`À travers son programme de Zakat, UMMATY vous offre la possibilité de remplir cette obligation en un seul clic. Nous veillons à ce que 100% de vos dons de Zakat (aucun frais de gestion prélevé) soient dépensés dans les catégories de bénéficiaires mentionnées dans le Saint Qur'an. Nous nous assurons que vos dons de Zakat bénéficient aux communautés les plus pauvres et permettent de leur offrir la possibilité de changer durablement leurs conditions de vie. Alors, purifiez votre argent et aidez vos frères et sœurs en faisant don de votre zakat maintenant.`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      